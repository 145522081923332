/* eslint-disable */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import moment from 'moment';
import { withAuth0 } from '@auth0/auth0-react';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, DatePicker, Checkbox, Radio, Tooltip, Upload, Button } from 'antd';
import map from 'lodash/map';
import { keys } from 'lodash';
import axios from 'axios';
import { faArrowCircleUp, faInfoCircle, faIdBadge } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlaceInput from 'Src/common/components/formItem/PlaceInput/index.js';
import NameInput from 'Src/common/components/formItem/NameInput/index.js';
import FroalaEditorComponent from 'Src/common/components/froalaEditor';
import { getKeyValue } from '../../utilities/data_util';
import { INPUT_TYPES } from '../../constants';
import './style.scss';
import PhoneNumberInput from '../formItem/PhoneNumberInput/index';
import { ExternalSourceSelect } from '../formItem/ExternalSourceSelect/index';
import MDEditor from '@uiw/react-md-editor';

const FormItem = Form.Item;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const RadioGroup = Radio.Group;
// file limits
const fileLimit = 20;
const videoLimit = 200;
const fileErrorString = 'Could not upload file, please try again.';
const windowWidth = window.innerWidth;

const numberOfFilesAllowed = 20;
// object to keep track of number of files uploaded
// using fieldinput id to track each field to avoid name collision
// not using state because async actions, data might be old or non existent for checking
const numberOfFilesUploaded = {};

const formattedFilesUrlIntoFileList = (urls) => {
  const urlList = urls.split(',');
  return urlList.map((url, index) => ({
    uid: `${index}`, // just to convert index to string value
    name: url.split('/')[url.split('/').length - 1],
    url,
  }));
};

class customFormItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileLoaded: '',
      fileList: [],
      // using state instead of global variable to avoid namespace issues
      multiFileUrls: '',
      fileErrorMessage: '',
      fileUploading: false,
      filePresignedUrl: '',
      // for rich text field
      froalaContext: '',
    };
    this.getPresignedPost = this.getPresignedPost.bind(this);
    this.beforeFileUpload = this.beforeFileUpload.bind(this);
    this.customRequest = this.customRequest.bind(this);
    this.handleFileRemove = this.handleFileRemove.bind(this);
    this.onRichTextContentChange = this.onRichTextContentChange.bind(this);
  }

  static defaultProps = {
    form: {},
    formInput: {},
    metadata: {},
    extraToolBarButtons: [],
    uploadImageOptions: {},
    onUploadSuccess: () => {},
  };

  static propTypes = {
    form: PropTypes.object,
    formInput: PropTypes.object,
    formRecord: PropTypes.object,
    customValidator: PropTypes.func,
    metadata: PropTypes.object,
    onUploadSuccess: PropTypes.func,
  };

  componentDidMount() {
    const { form } = this.props;
    const formItem = this.props.formInput;
    const { formRecord } = this.props;
    let formItemValue = '';
    const locationKey = `google_place_${formItem.key}`;
    let locationValue = null;
    formItemValue = getKeyValue(formRecord, formItem.key);
    switch (formItem.type) {
      case INPUT_TYPES.NUMBER:
        formItemValue = parseFloat(formItemValue);
        break;
      case INPUT_TYPES.DATE:
        if (formItemValue) {
          formItemValue = moment(formItemValue).isValid() ? moment(formItemValue) : null;
        }
        break;
      case INPUT_TYPES.MULTIPLE_FILE:
      case INPUT_TYPES.FILE:
        if (formItemValue) {
          this.setState({
            fileList: formattedFilesUrlIntoFileList(formItemValue),
            multiFileUrls: formItemValue,
          });
        }
        break;
      case INPUT_TYPES.RICH_TEXT:
        if (formItemValue) {
          this.setState({
            froalaContext: formItemValue,
          });
        }
        break;
      case INPUT_TYPES.GOOGLE_LOCATION:
        locationValue = getKeyValue(formRecord, locationKey);
        break;
      default:
        break;
    }
    if (formItemValue || formItemValue === false) {
      if (formItem.condition_field) {
        setTimeout(() => {
          form.setFieldsValue({
            [formItem.key]: formItemValue,
            [locationKey]: locationValue,
          });
        });
      } else {
        form.setFieldsValue({
          [formItem.key]: formItemValue,
          [locationKey]: locationValue,
        });
      }
    }
  }
  renderHelpTextWithIcon(helpText) {
    if (helpText) {
      return (
        <span data-color-mode="light" className="formfield-markdown-container">
          <FontAwesomeIcon icon={faInfoCircle} className="mr8" />
          <MDEditor.Markdown
            source={helpText}
            linkTarget="_blank"
            previewOptions={{
              linkTarget: '_blank',
            }}
          />
        </span>
      );
    }
    return '';
  }

  validateFormHandler = (validatorObject, rule, value, callback) => {
    if (validatorObject && this.props.customValidator(validatorObject, rule, value)) {
      callback(getKeyValue(validatorObject, 'message', 'Please enter value in correct format'));
    } else {
      callback();
    }
  };

  getHiddenStyleIfVisibleIsFalse(formItem) {
    const visible = getKeyValue(formItem, 'visible', true);

    if (!visible && !this.props.internalFieldEnabled) {
      return {
        display: 'none',
      };
    }
    return {};
  }

  // returns true if no condition on field is present
  // If condition on field is present, returns whether the condition is satisfied or not
  isConditionSatisfied(formItem) {
    const conditionField = getKeyValue(formItem, 'condition_field', null);
    if (conditionField) {
      const { form } = this.props;
      // Get the possible condition values
      const conditionValues = getKeyValue(formItem, 'condition_values', []);
      // Get the current filled in value
      const filledValue = form.getFieldValue(conditionField);
      return conditionValues.includes(filledValue);
    }
    // If field is not conditional return true
    return true;
  }

  renderFormItemLabel(formItem) {
    const tooltipText = getKeyValue(formItem, 'tooltip_text');
    if (tooltipText) {
      return (
        <span>
          <span style={{ marginRight: '0.5rem' }}>{formItem.label}</span>
          <Tooltip title={tooltipText}>
            <QuestionCircleOutlined />
          </Tooltip>
        </span>
      );
    }
    return formItem.label;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.fileUploading !== this.state.fileUploading && this.props?.handleLoadingChange) {
      this.props?.handleLoadingChange(this.state.fileUploading);
    }
  }

  /**
   *
   * @param {object} formItem
   * @returns {string} aria-label attribute for different components
   * Basically aria-label is important attribute from accessability point of view, it tell screen readers what exactly is the component.
   * So this function will get the aria-label for the form field and also add "Mandatory field" if the field is mandatory
   */
  getAriaLabel(formItem) {
    let ariaLabel = getKeyValue(formItem, 'label');
    const rules = getKeyValue(formItem, 'rules');
    if (rules?.required?.value) {
      ariaLabel += ' Mandatory Field';
    }
    return ariaLabel;
  }

  getCheckboxInitialValue(formItem) {
    const val = getKeyValue(formItem, 'default_value', '');
    // right now form sets default value as [''] , till we fix that problem with forms this is the patch we will need
    if (!val?.join('')) return [];
    return val;
  }

  getAuth0AuthToken() {
    if (this.props.auth0.isAuthenticated) {
      return this.props.auth0.getIdTokenClaims().then((tokenClaims) => tokenClaims?.__raw);
    }

    return Promise.resolve(null);
  }

  getPresignedPost(file) {
    // This function will call the backend with a file and key name
    // It returns a post policy with which we can upload to S3
    // It is a bunch of fields through which S3 can identify us, including access key, encoded policy, file key etc.
    // cleaning the file name to keep key and url valid
    const cleanedName = file.name.replace(/[^a-zA-Z0-9.-]+/g, '_');
    const { metadata } = this.props;
    const headers = {
      'X-CSRFToken': csrfToken,
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    return this.getAuth0AuthToken().then((jwtToken) => {
      if (jwtToken) {
        headers.authorization = `Bearer ${jwtToken}`;
      }

      return axios.post(
        '/api/v1/assets/',
        `name=${cleanedName}&source_type=${metadata.source_type || 'default'}&source_id=${
          metadata.source_id || 'default'
        }&access_level=${metadata.access_level || 'public'}`,
        { headers },
      );
    });
  }

  beforeFileUpload(file, multiple = true) {
    const { key } = this.props.formInput;
    if (!file.type.includes('video') && file.size / 1024 / 1024 > fileLimit) {
      this.setState({
        fileErrorMessage: `Your file is larger than ${fileLimit} MB, please try again with a smaller file.`,
      });
      return false;
    }

    // for single files
    if (!multiple) {
      // removing existing filelist
      this.setState({ fileList: [] });
      // Checking file size for a single file video
      if (file.type.includes('video') && file.size / 1024 / 1024 > videoLimit) {
        this.setState({
          fileErrorMessage: `Your video is larger than ${videoLimit} MB, please try again with a smaller file.`,
          fileLoaded: '',
        });
        return false;
      }
      return true;
    }
    // for multiple files

    // we're not including videos in multiple files.
    if (file.type.includes('video')) {
      this.setState({ fileErrorMessage: 'Cannot upload videos.' });
      return false;
    }
    if (numberOfFilesUploaded[key] === numberOfFilesAllowed) {
      this.setState({
        fileErrorMessage: `You can only upload ${numberOfFilesAllowed} files.`,
      });
      return false;
    }
    numberOfFilesUploaded[key] = numberOfFilesUploaded[key] ? numberOfFilesUploaded[key] + 1 : 1;
    return true;
  }

  handleFileRemove(file) {
    const formItem = this.props.formInput;
    const { fileList } = this.state;
    // for single upload or when there's a single file in multiple upload
    if (fileList.length === 1) {
      this.setState({ fileList: [], multiFileUrls: '' });
      this.props.form.setFieldsValue({ [formItem.key]: null });
    }
    // for multiple uploads, when more than 1 file is uploaded
    else if (fileList.length > 1) {
      let urls = this.state.multiFileUrls;
      const fileIndex = fileList.findIndex((f) => f.uid === file.uid);
      if (fileIndex > -1) {
        urls = urls.replace(new RegExp(`${file.url}(,?)`, 'g'), '');
        // remove trailing commas if they exist
        urls = urls.replace(/,$/, '');
        fileList.splice(fileIndex, 1);
        numberOfFilesUploaded[formItem.key] -= 1;
        this.setState({ fileList, multiFileUrls: urls, fileErrorMessage: '' });
        this.props.form.setFieldsValue({ [formItem.key]: urls });
      }
    }
    if (this.props?.handleAfterRemove) {
      this.props?.handleAfterRemove(file);
    }
  }

  customRequest({ file }) {
    const { fileList } = this.state;
    const formItem = this.props.formInput;
    this.setState({ fileUploading: true });
    if (numberOfFilesUploaded[formItem.key] !== numberOfFilesAllowed) {
      // Clear error messages, unless it's showing number of files exceeded message
      this.setState({ fileErrorMessage: '' });
    }
    this.getPresignedPost(file)
      .then((response) => {
        const postPolicy = response.data;
        const formData = new FormData();
        // Post Policy returned has 2 fields
        // fields - contains aws signature, key, policy, etc encoded
        // url - url of the bucket in s3 ie bucketname.aws.s3, this is where we POST to.
        keys(postPolicy.fields).map((field) => formData.append(field, postPolicy.fields[field]));
        const postUrl = postPolicy.url;
        formData.append('file', file);
        formData.append('Content-Type', file.type);
        // fileUrl is where the file is located, bucket + key name
        const fileUrl = response.data.asset_url;
        const assetId = response.data.asset_id;
        axios
          .post(postUrl, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            // updating fileobj to reflect status in antd upload component
            const fileObj = {
              uid: file.uid,
              status: 'done',
              url: fileUrl,
              thumbUrl: fileUrl,
              name: file.name,
              assetId: assetId,
            };
            fileList.push(fileObj);
            this.setState({ fileList });
            const formItem = this.props.formInput;
            let urls = '';
            // when first file is uploaded, no change in how to set values for single and multi
            // fileList will be greater than 1 only for multi file upload
            if (fileList.length > 1) {
              urls = `${this.state.multiFileUrls},${fileUrl}`;
              this.props.form.setFieldsValue({ [formItem.key]: urls });
              this.setState({ multiFileUrls: urls });
            } else if (fileList.length === 1) {
              this.setState({ multiFileUrls: fileUrl });
              this.props.form.setFieldsValue({ [formItem.key]: fileUrl });
            }
            if (this.props.onUploadSuccess) {
              this.props.onUploadSuccess(fileObj);
            }
            this.setState({ fileUploading: false });
          })
          .catch((err) => {
            this.setState({
              fileUploading: false,
              fileErrorMessage: fileErrorString,
            });
            // if upload failed, remove that count, should not happen.
            numberOfFilesUploaded[formItem.key] -= 1;
          });
      })
      .catch((err) =>
        this.setState({
          fileUploading: false,
          fileErrorMessage: fileErrorString,
        }),
      );
    // for when post policy is not returned, should not happen.
  }

  onRichTextContentChange(value) {
    const formItem = this.props.formInput;

    this.setState(
      {
        froalaContext: value,
      },
      () => this.props.form.setFieldsValue({ [formItem.key]: value }),
    );
  }

  getUploadFileType() {
    if (this.props.metadata && this.props.metadata.allowedFileTypes) {
      return this.props.metadata.allowedFileTypes;
    }
    return '.jpg, .jpeg, .png, .gif, .pdf, .xlsx, .docx, .ppt, .pptx, .mp4, .mov, .m4v, .mkv';
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItem = this.props.formInput;

    // TODO: better way to handle css here for pre-icon-form-internal-field
    return (
      <div style={this.getHiddenStyleIfVisibleIsFalse(formItem)} className="custom-form-wrapper">
        <If condition={this.isConditionSatisfied(formItem)}>
          <If condition={this.props.internalFieldEnabled}>
            <div className="pre-icon-form-internal-field">
              <Tooltip title={'This field is for internal use. Only administrators can view and edit this field.'}>
                <FontAwesomeIcon icon={faIdBadge} className="internal-field-icon" />
              </Tooltip>
            </div>
          </If>
          <If
            condition={
              [INPUT_TYPES.TEXT, INPUT_TYPES.EMAIL, INPUT_TYPES.URL, INPUT_TYPES.NUMBER].indexOf(formItem.type) > -1
            }>
            <FormItem
              label={this.renderFormItemLabel(formItem)}
              aria-label={this.getAriaLabel(formItem)}
              extra={this.renderHelpTextWithIcon(getKeyValue(formItem, 'help_text'))}
              colon={false}
              {...(this.props.labelCol ? { labelCol: this.props.labelCol } : {})}
              {...(this.props.wrapperCol ? { wrapperCol: this.props.wrapperCol } : {})}>
              {getFieldDecorator(formItem.key, {
                rules: [
                  {
                    required: getKeyValue(formItem, 'rules__required__value', false),
                    message: getKeyValue(formItem, 'rules__required__message', 'Please enter value'),
                  },
                  {
                    max: getKeyValue(formItem, 'rules__max__value', '-'),
                    message: getKeyValue(formItem, 'rules__max__message', ''),
                  },
                  {
                    validator: this.validateFormHandler.bind(this, getKeyValue(formItem, 'validator', false)),
                  },
                  ...(formItem.type != INPUT_TYPES.TEXT
                    ? [
                        {
                          type: formItem.type,
                          message: getKeyValue(formItem, 'rules__type__message', `Please enter valid ${formItem.type}`),
                        },
                      ]
                    : []),
                ],
              })(
                <Input
                  size={getKeyValue(formItem, 'input_size', 'default')}
                  placeholder={
                    formItem.type === INPUT_TYPES.URL ? 'https://www.google.com' : getKeyValue(formItem, 'placeholder')
                  }
                  disabled={getKeyValue(formItem, 'disabled', false)}
                  aria-label={this.getAriaLabel(formItem)}
                />,
              )}
            </FormItem>
          </If>
          <If condition={formItem.type === INPUT_TYPES.NAME}>
            <NameInput
              form={this.props.form}
              formInput={formItem}
              formRecord={this.props.formRecord}
              renderHelpTextWithIcon={this.renderHelpTextWithIcon}
            />
          </If>
          <If condition={formItem.type === INPUT_TYPES.MULTIPLE_FILE}>
            <FormItem
              label={this.renderFormItemLabel(formItem)}
              aria-label={this.getAriaLabel(formItem)}
              extra={this.renderHelpTextWithIcon(getKeyValue(formItem, 'help_text'))}
              colon={false}>
              <Upload
                listType="text"
                customRequest={this.customRequest}
                aria-label={this.getAriaLabel(formItem)}
                multiple={true}
                // need to rebuild urls string
                onRemove={(file) => this.handleFileRemove(file)}
                beforeUpload={(file) => this.beforeFileUpload(file, true)}
                fileList={this.state.fileList}
                accept={this.getUploadFileType()}
                showUploadList={{
                  showPreviewIcon: false,
                  showDownloadIcon: false,
                  showRemoveIcon: true,
                }}>
                <Button className="arc-btn-subtle" loading={this.state.fileUploading}>
                  <FontAwesomeIcon icon={faArrowCircleUp} className="mr4" />
                  Upload files
                </Button>
              </Upload>
              {this.state.fileErrorMessage && <span style={{ color: 'crimson' }}>{this.state.fileErrorMessage}</span>}
              {getFieldDecorator(formItem.key, {
                rules: [
                  {
                    required: getKeyValue(formItem, 'rules__required__value', false),
                    message: getKeyValue(formItem, 'rules__required__message', 'Please upload file'),
                  },
                  {
                    validator: this.validateFormHandler.bind(this, getKeyValue(formItem, 'validator', false)),
                  },
                ],
              })(<Input type="hidden" />)}
            </FormItem>
          </If>
          <If condition={formItem.type === INPUT_TYPES.DATE}>
            <FormItem
              label={this.renderFormItemLabel(formItem)}
              aria-label={this.getAriaLabel(formItem)}
              extra={this.renderHelpTextWithIcon(getKeyValue(formItem, 'help_text'))}
              colon={false}>
              {getFieldDecorator(formItem.key, {
                rules: [
                  {
                    required: getKeyValue(formItem, 'rules__required__value', false),
                    message: getKeyValue(formItem, 'rules__required__message', 'Please enter value'),
                  },
                ],
              })(
                <DatePicker
                  showTime={getKeyValue(formItem, 'show_time', false)}
                  aria-label={this.getAriaLabel(formItem)}
                  format={
                    getKeyValue(formItem, 'show_time', false)
                      ? 'YYYY-MM-DD HH:mm:ss'
                      : getKeyValue(formItem, 'date_format', 'YYYY-MM-DD')
                    // TODO: YYYY-MM-DD  to DD MMM YYYY
                  }
                  style={{ width: '100%' }}
                  size={getKeyValue(formItem, 'input_size', 'default')}
                  disabled={getKeyValue(formItem, 'disabled', false)}
                />,
              )}
            </FormItem>
          </If>
          <If condition={formItem.type === INPUT_TYPES.TEXTAREA}>
            <FormItem
              label={this.renderFormItemLabel(formItem)}
              aria-label={this.getAriaLabel(formItem)}
              extra={this.renderHelpTextWithIcon(getKeyValue(formItem, 'help_text'))}
              colon={false}>
              {getFieldDecorator(formItem.key, {
                rules: [
                  {
                    required: getKeyValue(formItem, 'rules__required__value', false),
                    message: getKeyValue(formItem, 'rules__required__message', 'Please enter value'),
                  },
                  {
                    max: getKeyValue(formItem, 'rules__max__value', '-'),
                    message: getKeyValue(formItem, 'rules__max__message', ''),
                  },
                ],
              })(
                <Input.TextArea
                  autosize={{ minRows: 2, maxRows: 6 }}
                  placeholder={getKeyValue(formItem, 'placeholder')}
                  aria-label={this.getAriaLabel(formItem)}
                />,
              )}
            </FormItem>
          </If>
          <If condition={formItem.type === INPUT_TYPES.RICH_TEXT}>
            <FormItem
              label={this.renderFormItemLabel(formItem)}
              aria-label={this.getAriaLabel(formItem)}
              extra={this.renderHelpTextWithIcon(getKeyValue(formItem, 'help_text'))}
              colon={false}>
              <FroalaEditorComponent
                // default value as hard-coded for now , we improve this values as props when we have few more case to use this.
                toolbarInline={false}
                aria-label={this.getAriaLabel(formItem)}
                showCharacterCount={false}
                onModelChange={(context) => this.onRichTextContentChange(context)}
                model={this.state.froalaContext}
                toolbarButtons={[
                  'bold',
                  'italic',
                  'underline',
                  'paragraphFormat',
                  'formatOL',
                  'formatUL',
                  'insertLink',
                  ...this.props.extraToolBarButtons,
                ]}
                uploadImageOptions={this.props.uploadImageOptions}
                handleBeforeUpload={this.props.handleBeforeUpload}
                quickInsertButtons={['ul', 'ol', 'hr']}
              />
              {getFieldDecorator(formItem.key, {
                rules: [
                  {
                    required: getKeyValue(formItem, 'rules__required__value', false),
                    message: getKeyValue(formItem, 'rules__required__message', 'Please enter value'),
                  },
                  {
                    max: getKeyValue(formItem, 'rules__max__value', '-'),
                    message: getKeyValue(formItem, 'rules__max__message', ''),
                  },
                ],
              })(<Input type="hidden" aria-label={this.getAriaLabel(formItem)} />)}
            </FormItem>
          </If>
          <If condition={[INPUT_TYPES.SELECT, INPUT_TYPES.SELECT_RANGE].indexOf(formItem.type) > -1}>
            <Choose>
              <When condition={formItem.choice_source_identifier}>
                <ExternalSourceSelect
                  _this={this}
                  form={this.props.form}
                  formItem={formItem}
                  getKeyValue={getKeyValue}
                />
              </When>
              <Otherwise>
                <FormItem
                  label={this.renderFormItemLabel(formItem)}
                  aria-label={this.getAriaLabel(formItem)}
                  extra={this.renderHelpTextWithIcon(getKeyValue(formItem, 'help_text'))}
                  colon={false}>
                  {getFieldDecorator(formItem.key, {
                    initialValue: getKeyValue(formItem, 'default_value', ''),
                    rules: [
                      {
                        required: getKeyValue(formItem, 'rules__required__value', false),
                        message: getKeyValue(formItem, 'rules__required__message', 'Please enter value'),
                      },
                      {
                        validator: this.validateFormHandler.bind(this, getKeyValue(formItem, 'validator', false)),
                      },
                    ],
                  })(
                    <Choose>
                      <When condition={windowWidth <= 640}>
                        <Select style={{ width: '100%' }}>
                          {map(formItem.options, (option) => (
                            <Option value={option.value} key={option.value}>
                              {option.text || option.label}
                            </Option>
                          ))}
                        </Select>
                      </When>
                      <Otherwise>
                        <Select
                          style={{ width: '100%' }}
                          aria-label={this.getAriaLabel(formItem)}
                          allowClear={this.props.disableAllowClear ? false : true}
                          showSearch={getKeyValue(formItem, 'show_search', true)}
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          disabled={getKeyValue(formItem, 'disabled', false)}>
                          {map(formItem.options, (option) => (
                            <Option value={option.value} key={option.value} aria-label={option.text || option.label}>
                              {option.text || option.label}
                            </Option>
                          ))}
                        </Select>
                      </Otherwise>
                    </Choose>,
                  )}
                </FormItem>
              </Otherwise>
            </Choose>
          </If>
          <If condition={formItem.type === INPUT_TYPES.CHECKBOX}>
            <FormItem
              label={this.renderFormItemLabel(formItem)}
              aria-label={this.getAriaLabel(formItem)}
              extra={this.renderHelpTextWithIcon(getKeyValue(formItem, 'help_text'))}
              colon={false}>
              {getFieldDecorator(formItem.key, {
                initialValue: this.getCheckboxInitialValue(formItem),
                rules: [
                  {
                    required: getKeyValue(formItem, 'rules__required__value', false),
                    message: getKeyValue(formItem, 'rules__required__message', 'Please select value'),
                  },
                ],
              })(<CheckboxGroup aria-label={this.getAriaLabel(formItem)} options={formItem.options} />)}
            </FormItem>
          </If>
          <If condition={formItem.type === INPUT_TYPES.RADIO}>
            <FormItem
              label={this.renderFormItemLabel(formItem)}
              aria-label={this.getAriaLabel(formItem)}
              extra={this.renderHelpTextWithIcon(getKeyValue(formItem, 'help_text'))}
              colon={false}>
              {getFieldDecorator(formItem.key, {
                initialValue: getKeyValue(formItem, 'default_value', ''),
                rules: [
                  {
                    required: getKeyValue(formItem, 'rules__required__value', false),
                    message: getKeyValue(formItem, 'rules__required__message', 'Please select an option'),
                  },
                ],
              })(<RadioGroup aria-label={this.getAriaLabel(formItem)} options={formItem.options} />)}
            </FormItem>
          </If>
          <If condition={formItem.type === INPUT_TYPES.FILE}>
            <FormItem
              label={this.renderFormItemLabel(formItem)}
              aria-label={this.getAriaLabel(formItem)}
              extra={this.renderHelpTextWithIcon(getKeyValue(formItem, 'help_text'))}>
              <Upload
                // replacing old getUrl method with the new post policy to prevent 2 ways for tdoing the same thing
                // POST policy more robust
                // will remove backend code too
                // using picture type for consistency, wont show thumbnail if not img, just a doc icon
                aria-label={this.getAriaLabel(formItem)}
                listType={this.props?.listType || 'text'}
                fileList={this.state.fileList}
                accept={this.getUploadFileType()}
                multiple={false}
                customRequest={this.customRequest}
                beforeUpload={(file) => this.beforeFileUpload(file, false)}
                showUploadList={{
                  showPreviewIcon: this.props?.showPreviewIcon || false,
                  showDownloadIcon: false,
                  showRemoveIcon: true,
                }}
                onRemove={(file) => this.handleFileRemove(file)}>
                {this.props?.children ? (
                  this.props.children
                ) : (
                  <Button className="arc-btn-subtle" loading={this.state.fileUploading}>
                    <FontAwesomeIcon icon={faArrowCircleUp} className="mr4" />
                    Upload file
                  </Button>
                )}
              </Upload>
              {this.state.fileErrorMessage && <span style={{ color: 'crimson' }}>{this.state.fileErrorMessage}</span>}
              {getFieldDecorator(formItem.key, {
                rules: [
                  {
                    required: getKeyValue(formItem, 'rules__required__value', false),
                    message: getKeyValue(formItem, 'rules__required__message', 'Please upload file'),
                  },
                  {
                    validator: this.validateFormHandler.bind(this, getKeyValue(formItem, 'validator', false)),
                  },
                ],
              })(<Input type="hidden" />)}
            </FormItem>
          </If>
          <If condition={formItem.type === INPUT_TYPES.GOOGLE_LOCATION}>
            <PlaceInput
              form={this.props.form}
              formInput={formItem}
              formRecord={this.props.formRecord}
              renderHelpTextWithIcon
            />
          </If>
          <If condition={formItem.type === INPUT_TYPES.PHONE_NUMBER}>
            <PhoneNumberInput
              suffix={true}
              form={this.props.form}
              formRecord={this.props.formRecord}
              validateTrigger={'onChange'}
              formItem={{
                ...formItem,
                required: getKeyValue(formItem, 'rules__required__value', false),
                message: 'Please enter a valid phone number',
              }}
              extra={this.renderHelpTextWithIcon(getKeyValue(formItem, 'help_text'))}
            />
          </If>
        </If>
      </div>
    );
  }
}

export default withAuth0(customFormItem);
