/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable complexity */
/* eslint-disable react/function-component-definition */
import React from 'react';
import ReactPlayer from 'react-player/lib';
import { LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { Spin } from 'antd';
import { string, bool } from 'prop-types';
import { getSrcFromSrcSet } from 'Src/common/utilities/dom_utils';
import ProgressiveFadedImage from 'Src/common/components/progressiveFadedImage';
import { BREAKPOINT, ASPECT_RATIO, MAX_CONTAINER_WIDTH } from '../../constants';
import './style.scss';
import { isEmpty } from 'lodash';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const PlayButton = () => (
  <div className="play-button">
    <FontAwesomeIcon icon={faPlay} />
    Play
  </div>
);

const HeroBanner = (props) => {
  const width = Math.min(document.documentElement.clientWidth, MAX_CONTAINER_WIDTH);
  const height = `${width < BREAKPOINT ? width : width / ASPECT_RATIO}px`;
  return (
    <div id="hero-wrapper" className={props.isDefaultCoverImage ? 'hero-has-default-cover' : ''} role="banner">
      <div id="hero-banner" style={{ height }}>
        <Choose>
          <When
            condition={
              !isEmpty(props?.bannerType) && props.bannerType === 'video' && props?.bannerVideo?.output_source_url
            }>
            <ReactPlayer
              url={props?.bannerVideo?.output_source_url || ''}
              height={height}
              loop={true}
              width={'100%'}
              controls={true}
              playing={props?.bannerVideo?.thumbnail?.download_url ? true : false}
              muted={false}
              className="video-wrapper"
              light={props?.bannerVideo?.thumbnail?.download_url || ''}
              playIcon={<PlayButton />}
            />
          </When>
          <When
            condition={
              !isEmpty(props?.bannerType) && props.bannerType === 'video' && props?.bannerVideo?.thumbnail?.download_url
            }>
            <div
              role="img"
              id="hero-banner-image"
              aria-label={props.title}
              style={{
                height,
                backgroundImage: `url(${props?.bannerVideo?.thumbnail?.download_url || ''})`,
                ...(!props.isLoaded ? { filter: 'blur(7px)' } : {}),
              }}
            />
          </When>
          <When condition={props.isImageResizingEnabled}>
            <ProgressiveFadedImage
              path={props.bannerImage}
              className="hero-banner-wrapper"
              render={(loading, src, srcSetData) => (
                <div
                  role="img"
                  aria-label={props.title}
                  id="hero-banner-image"
                  style={{
                    height,
                    backgroundImage: `url(${getSrcFromSrcSet(srcSetData.srcSet, src)})`,
                    filter: loading ? 'blur(10px)' : 'none',
                    opacity: loading ? 0.5 : 1,
                    transition: 'filter 700ms ease-in',
                  }}
                />
              )}
            />
          </When>
          <Otherwise>
            <div
              role="img"
              id="hero-banner-image"
              aria-label={props.title}
              style={{
                height,
                backgroundImage: `url(${props.bannerImage})`,
                ...(!props.isLoaded ? { filter: 'blur(7px)' } : {}),
              }}
            />
          </Otherwise>
        </Choose>

        <div id="hero-college-logo-block">
          {!props.isLoaded ? (
            <Spin indicator={antIcon} id="hero-banner-loader" />
          ) : (
            <img src={window.customerLogo} alt={`${window.collegeName} logo`} id="hero-college-logo" />
          )}
        </div>
        <If condition={props.logo}>
          <img src={props.logo} id="hero-logo" alt="Hero banner logo" />
        </If>
      </div>
      <Choose>
        <When condition={props.isImageResizingEnabled}>
          <ProgressiveFadedImage
            className="hero-overlay-wrapper-container"
            path={props.bannerImage}
            render={(loading, src, srcSetData) => (
              <div
                id="hero-wrapper-overlay"
                style={{
                  backgroundImage: getSrcFromSrcSet(srcSetData.srcSet)
                    ? `url(${getSrcFromSrcSet(srcSetData.srcSet)}), url(${props.bannerImage})`
                    : null,
                  filter: loading ? 'blur(10px)' : 'none',
                  opacity: loading ? 0.5 : 1,
                  transition: 'filter 700ms ease-in',
                }}
              />
            )}
          />
        </When>
        <Otherwise>
          <div id="hero-wrapper-overlay" style={{ backgroundImage: `url(${props.bannerImage})` }} />
        </Otherwise>
      </Choose>
    </div>
  );
};

HeroBanner.propTypes = {
  isLoaded: bool.isRequired,
  bannerImage: string.isRequired,
  title: string.isRequired,
  logo: string,
};

HeroBanner.defaultProps = {
  logo: null,
};

export default HeroBanner;
