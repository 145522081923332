import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PrinterOutlined } from '@ant-design/icons';
import { Row, Col, Button, Divider } from 'antd';
import { intersection, isEmpty, includes } from 'lodash';

// === APP UTILS AND CONSTANTS === //
import { ALUMNI_BASE_URL } from './../../routes';
import { CRITERIA_AFFILIATION, CRITERIA_CUSTOM, OPEN_TICKET } from './../../constants';
import { getAgendaItems } from './../../utils/app_moment_utils';

import ItineraryAgendaItem from './itineraryAgendaItem';

import './style.scss';
import { EVENT_ITINERARIES_API_PATH } from '../../../adminEvents/endpoints';
import instance from '../../../common/utilities/axios_util';

const Itinerary = (props) => {
  const [itineraryAgendaItems, setItineraryAgendaItems] = useState([]);
  let eventItineraries = [];
  const { eventData, registrationData } = props;
  const { guestId } = props.match.params;
  const eventDetailUrl = ALUMNI_BASE_URL.replace(':eventSlug', eventData.slug);

  if (isEmpty(registrationData) || !registrationData.registration_status || !guestId) {
    redirectToEventPage();
  }

  let guestData = registrationData.guests.filter((guest) => guest.id == guestId)[0];

  if (!guestData) {
    redirectToEventPage();
  }

  const openTickets = eventData.tickets
    .filter((ticket) => ticket.ticket_type === OPEN_TICKET)
    .map((ticket) => ticket.id);

  const openBoughtTickets = registrationData.tickets
    .filter((item) => includes(openTickets, item.ticket.id))
    .map((item) => {
      return { id: item.ticket.id, quantity: item.quantity };
    });

  async function fetchEventItineraries(eventId) {
    const path = EVENT_ITINERARIES_API_PATH.replace('{}', eventId).concat('?skip_pagination=true');
    await instance
      .get(path)
      .then((response) => {
        response.data?.forEach((item) => {
          item.is_itinerary = true;
        });
        eventItineraries = response.data?.filter((item) => item.is_active);
      })
      .catch(() => {});
  }

  function setGuestData() {
    const seatedBoughtTickets = guestData.tickets.map((item) => item.ticket.id);
    const allBoughtTickets = [...seatedBoughtTickets, ...openBoughtTickets.map((item) => item.id)];
    setAgendas(allBoughtTickets, guestData);
  }

  function setAgendas(tickets, guestData) {
    const eventAgendas = eventData.agendas.filter((item) => !item.is_hide_on_itinerary);
    const agendaItems = getAgendaItems(eventAgendas.concat(eventItineraries));
    agendaItems.forEach((item) => {
      item.data.forEach((agenda) => {
        if (!!agenda.is_itinerary) {
          if (!!agenda.depends_on) {
            let criteria = agenda.depends_on.criteria;
            if (
              criteria === CRITERIA_AFFILIATION &&
              !guestData.affiliations.some((affiliation) => agenda.depends_on.allowed_values?.includes(affiliation.id))
            ) {
              agenda.is_visible = false;
            } else if (criteria === CRITERIA_CUSTOM) {
              let value = guestData.form_entry?.field_entries?.find(
                (field) => field.field_id == agenda.depends_on.field_id,
              )?.value;
              agenda.is_visible = agenda.depends_on.allowed_values?.map(String).includes(value);
            } else {
              agenda.is_visible = true;
            }
          } else {
            agenda.is_visible = true;
          }
        } else {
          const agendaTickets = agenda.tickets.map((ticket) => ticket.id);
          agenda.is_visible = !!intersection(tickets, agendaTickets).length || !agendaTickets.length;
          agenda.open_tickets = getOpenTickets(agendaTickets);
        }
      });
    });
    setItineraryAgendaItems(agendaItems);
  }

  function getOpenTickets(agendaTickets) {
    let openTicketCount = 0;
    openBoughtTickets.forEach((ticket) => {
      if (includes(agendaTickets, ticket.id)) {
        openTicketCount += ticket.quantity;
      }
    });
    return openTicketCount;
  }

  function redirectToEventPage() {
    window.open(eventDetailUrl, '_self');
    return false;
  }

  useEffect(() => {
    (async function fetchData() {
      await fetchEventItineraries(eventData.id);
      setGuestData();
    })();
  }, []);

  return (
    <div id="itinerary-manager">
      <Row justify="space-between" align="middle" id="itinerary-header">
        <Col>
          <Button icon={<PrinterOutlined />} type="primary" onClick={() => window.print()}>
            Print
          </Button>
        </Col>
        <Col>
          <a href="https://docs.almabase.com/events-3-0/personal-itineraries" target="_blank">
            How it works?
          </a>
        </Col>
      </Row>
      <Row>
        <Col span={24} id="itinerary-event-detail">
          <img src={window.customerLogo} alt={`${window.collegeName} logo`} />
          <p className="arc-H200">{eventData.name}</p>
          <Divider type="horizontal" />
        </Col>
        <Col span={24} id="itinerary-guest-detail">
          <p className="arc-H150">{`Hi ${guestData.first_name || ''}!`}</p>
          <p className="arc-p">
            We're really glad you're joining us! Here is your personalized itinerary to help you follow events on your
            schedule.
          </p>
        </Col>
        <Col span={24} id="itinerary-guest-agendas">
          <Row>
            {itineraryAgendaItems.map((agenda, index) => {
              return (
                <ItineraryAgendaItem
                  key={index}
                  agenda={agenda}
                  isParentGuest={guestData.is_parent_registration}
                  parentGuestName={registrationData.first_name}
                />
              );
            })}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  eventData: state.eventDataReducer.data || {},
  registrationData: state.registrationDataReducer.data || {},
});

export default withRouter(connect(mapStateToProps, null)(Itinerary));
